<template>
  <div class="vlt-setting">
    <p class="notification-header">Notification</p>
    <div class="vlt-sub">
      <div class="vlt-radio inline" v-for="(notify, index) in notificatonList" :key="index">
        <input
          :id="notify"
          name="radio-notification"
          type="radio"
          v-model="notification"
          :value="notify"
          @click="notificationToggle"
        />
        <label :for="notify" class="radio-label">{{ notify }}</label>
      </div>
    </div>
    <!-- <p>Quality</p>
    <div class="vlt-sub">
      <div class="vlt-radio inline" v-for="(qualitys, index) in qualityList" :key="index">
        <input :id="qualitys" name="radio-quality" type="radio" v-model="quality" :value="qualitys" />
        <label :for="qualitys" class="radio-label">{{ qualitys }}</label>
      </div>
    </div>-->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      notificatonList: ["On", "Off"],
      notification: "On",
      qualityList: ["Auto", "High", "Medium", "Low"],
      quality: "Auto"
    };
  },
  computed: {
    ...mapGetters(["subscriberId", "notificationStatus"])
  },
  watch: {
    notificationStatus(val) {
      console.log("entered watch")
      if (val) {
        this.notification = "On";
      } else {
        this.notification = "Off";
      }
    }
  },
  methods:{
    ...mapActions([
      "unsubscribeFromTopics",
      "subscribeVendorAndSubscriberTopics",
      "initFCMNotifications",
    ]),
    getStatus() {
      let status = JSON.parse(localStorage.getItem("notificationStatus"));
      if (!status) {
        this.notification = "Off";
      } else {
        this.subscribeVendorAndSubscriberTopics();
      }
    },
     notificationToggle() {
      if (this.notification === "On") {
        this.unsubscribeFromTopics(true);
      } else {
        this.subscribeVendorAndSubscriberTopics();
        // this.initFCMNotifications();
      }
    },
  },
  created() {
   this.getStatus()
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-setting {
  @include breakpoint(mobile) {
    padding: 0 8%;
  }

  .notification-header{
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    color: $font-clr-white;
    font-size:1.25rem;
    // margin-top: 5%;
  }
  .vlt-sub {
    display: flex;
    margin: 3%;
  }

}
.vlt-radio {
  position: relative;
  width: 15%;
  @include breakpoint(mobile) {
    width: 22%;
  }
  &.inline {
    display: inline-block;
    margin-right: 15px;
  }
  input {
    opacity: 0;
    position: absolute;
    &:checked {
      + label::before {
        border: 2px solid $font-clr-white;
      }
      + label::after {
        width: 10px;
        height: 10px;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        background-color: $font-clr-white;
      }
      + label {
        opacity: 1;
        font-weight: bold;
        color: $font-clr-white;
      }
    }
  }
  label {
    position: relative;
    padding-left: 35px;
    font-weight: $font-weight-regular;
    font-size:1rem;
    cursor: pointer;
    &::before,
    &::after {
      position: absolute;
      content: "";
    }
    &::before {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      left: 0;
      color: $font-clr-white-1;
      top: 0;
      border: 2px solid $font-clr-white-1;
    }
  }
}

@media only screen and (max-width: 768px) {
  // .vlt-setting {
  //   padding: 0 8%;
  // }
  // .vlt-radio {
  //   width: 22%;
  // }
}
</style>